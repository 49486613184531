

.App  {
  

}
.App .navbar-brand {
  font-weight: bold;
}

.selected {
background-color: blue;

}

