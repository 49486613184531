select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type=file] {
  width: 100%;
}
html {
  overflow-y: scroll;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}

.nav-link.focus {
  color: #8fba1c;
}

.nav-link.focus {
  color: #8fba1c;
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active {
  color: #8fba1c;
}


.navBottom {
  position: fixed; 
  bottom:0%;
  width:100%;
}
.btn-primary {
  color: #fff;
  background-color: #b6b6b6;
  border-color: #a0a0a0;
}
    .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
      color: #fff;
      background-color: #8fba1c;
      border-color: #789b17;
  }
  .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.1rem rgba(144, 186, 28, 0.3);
}
  .btn-primary:hover {
    color: #fff;
    background-color: #8fba1c;
    border-color: #789b17;
}

.mobileLogo {
background-color: #c0c0c042;

}


footer.page-footer a {
  color: #8fba1c;
}

.navbar, .footerShadow{
  font-weight: 300;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
}
.table th, .table td {
  vertical-align: middle;
  border-top: 1px solid #dee2e6;
}